/**
 * Creates a debounced function that delays invoking the provided function until at least `ms` milliseconds have elapsed since the last time it was invoked.
 *
 * Each time the debounced function is invoked, clear the current pending timeout with `clearTimeout()` and use `setTimeout()` to create a new timeout that delays invoking the function until at least `ms` milliseconds has elapsed. Use `Function.prototype.apply()` to apply the `this` context to the function and provide the necessary arguments.
 * Omit the second argument, `ms`, to set the timeout at a default of 0 ms.
 *
 * @param { Function } fn - Target function.
 * @param {number} ms - @default 180ms.
 * @returns {Function} The debounced function.
 */
export const debounce = (fn: (...args: any[]) => void, ms = 180) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}
